<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Workflow Approval List"
          @onClickAddNewButton="onClickAddNewButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <ListTable :approvalMatrixList="approvalMatrixList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <GlobalLoader />
  </div>
</template>

<script setup>
import TitleButton     from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable       from '@/components/molecule/procurement/organization-settings/approval-matrix/WorkflowApprovalListingTable.vue'
import Pagination      from '@/components/atom/Pagination'
import Loader          from '@/components/atom/LoaderComponent'
import {inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

const $route = useRoute();
const $router = useRouter();
const $store = useStore();

const {fetchWorkflowList} = handleWorkflow();

const quick_search = ref(null);
const offset = ref(20);
const approvalMatrixList = ref([]);
const page = ref(1);

const onClickAddNewButton = () => {
  let params = $route.params;
  let query = $route.query;

  $router.push({
      name : 'approval-matrix-add',
      params: params,
      query: query
  });
}

const onClickSearchButton = () => {
    getApprovalMatrix();
}

function setPagination(data) {
    if(!data.data.length) return
    $store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery  = Object.assign({}, $route.query)
    routeQuery.page = page
    await $router.push({path: $route.path, query: routeQuery})
    await getApprovalMatrix();
}

const getApprovalMatrix = async () => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}&offset=${offset.value}`;
    if (quick_search.value !== null) {
        query += `&name=${quick_search.value}`
    }
    if ($route.query.page) {
        query += '&page=' + $route.query.page
    }
    await fetchWorkflowList(query)
        .then(res => {
            if(res.date) {
                approvalMatrixList.value = res.date.data
                setPagination(res.date);
            }
        })
        .catch(err => {
            showError('Something went wrong please try again');
        })
    $store.state.loading = false;
}

onMounted(() => {
    getApprovalMatrix();
});

</script>
