<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Form Name</th>
                        <th>Workflow Name</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in approvalMatrixList">
                        <td>{{ item.page.label ?? 'N/A' }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.start_date }}</td>
                        <td>{{ item.end_date }}</td>
                        <td>{{ item.status }}</td>
                        <td>
                            <span style="cursor: pointer" @click="goToEdit(item.id)">View</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
        </div>
    </div>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";

    const $route = useRoute();
    const $router = useRouter();

    const $props = defineProps({
        approvalMatrixList: {
            type: Array,
            required: true
        }
    });

    const goToEdit = (id) => {
        $router.push({name: 'approval-matrix-edit',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId,
                workflowId: id
            },
            query: {
                start: $route.query.start,
                end:  $route.query.end
            }
        })
    }
</script>
